.popUpWrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.63);
}
.popUp {
    position: absolute;
    background-color: white;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 20px 20px;
    border: 3px solid #f56565;
    border-radius: 8px;
    max-width: 600px;
}
.popUp h2 {
    font-weight: bold;
}
.popUp_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popUp_content p{
    text-align: center;
    margin: 20px 0 0 0;
}
.popUp_content button {
    background-color: #f56565;
    color: white;
    padding: 8px 20px;
    border-radius: 8px;
}
.close_button {
   position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold;
    transform: rotate(45deg);
}
.input_confirm {
    background-color: #F0F0F0;
    margin: 20px 0 10px 0;
    padding: 0 6px;
    border-radius: 6px;
    color: gray;
}
